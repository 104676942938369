$('#passengers-menu .input-icon').on('click', function () {
    let ageFields = "";
    let action = 'add';
    let value = $(this).siblings('input').eq(0).val();
    if ($(this).hasClass('add')) {
        value++;
    }
    if ($(this).hasClass('substract')) {
        value--;
        action = 'substract';
    }

    // Limit max and min values
    if (value < 1) {
        value = 1;
    }
    if (value > 9) {
        value = 9;
    }

    if (value > 0) {
        let currentNumPassengers = $('.age-selection>*').length;
        if (action === 'add') {
            for (let i = currentNumPassengers; i < value; i++) {
                ageFields += '<div>';
                ageFields += '<label for="age-selection-' + (i+1) + '">Passenger ' + (i+1) + '</label>';
                ageFields += '<select id="age-selection-' + (i+1) + '" name="age_selection[]" class="form-control">';
                for (let j = 0; j <= 99; j++) {
                    if (j == 18) {
                        ageFields += '<option value="' + j + '" selected>' + j + '</option>';
                    } else {
                        ageFields += '<option value="' + j + '">' + j + '</option>';
                    }
                }
                ageFields += '</select>';
                ageFields += '</div>';
            }

            $('.age-selection').append(ageFields);
        }
        else {
            $('.age-selection>*').each(function(index) {
                if (index >= value) {
                    $(this).remove();
                }
            });
        }
    }

    $(this).siblings('input').eq(0).val(value);
    $('form[name="searchForm"] .num_passengers').val(value);

    // Refresh vehicle max qty with number of passengers selected
    let currentVehicles = $('select[name="vehicle_qty"]>*').length;
    let options = "";
    // Remove vehicles qty
    if (currentVehicles > value) {
        for (let i = 0; i < currentVehicles - value; i++) {
            $('select[name="vehicle_qty"]').children().last().remove();
        }
    }
    // Add vehicles qty
    if (currentVehicles < value) {
        for (let i = 1; i <= value - currentVehicles; i++) {
            options += '<option value="' + (currentVehicles + i) + '">' + (currentVehicles + i) + '</option>';
        }
        $('select[name="vehicle_qty"]').append(options);
    }
});
